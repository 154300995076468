import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
Alpine.plugin(collapse)

import { customModal, customModalLink } from '~/alpine/custom_modal'
Alpine.data('customModal', customModal)
Alpine.data('customModalLink', customModalLink)

import modalForm from '~/alpine/modal_form'
Alpine.data('modalForm', modalForm)

import { studentSearch, studentSort } from '~/alpine/student_search'
Alpine.data('studentSearch', studentSearch)
Alpine.data('studentSort', studentSort)

import registrationForm from '~/alpine/registration_form'
Alpine.data('registrationForm', registrationForm)

import actionWithConfirmation from '~/alpine/action_with_confirmation'
Alpine.data('actionWithConfirmation', actionWithConfirmation)

import toggle from '~/alpine/toggle'
Alpine.data('toggle', toggle)

import productPackage from '~/alpine/product_package'
Alpine.data('productPackage', productPackage)

import inAppPurchaseSubject from '~/alpine/in_app_purchase_subject'
Alpine.data('inAppPurchaseSubject', inAppPurchaseSubject)

import adminDrivingTestQuestion from '~/alpine/driving_test_question'
Alpine.data('adminDrivingTestQuestion', adminDrivingTestQuestion)

import invoice from '~/alpine/invoice'
Alpine.data('invoice', invoice)

import liveCourse from '~/alpine/live_course'
Alpine.data('liveCourse', liveCourse)

import creditInvoice from '~/alpine/credit_invoice'
Alpine.data('creditInvoice', creditInvoice)

import invoicePayments from '~/alpine/invoice_payments'
Alpine.data('invoicePayments', invoicePayments)

import paymentReminder from '~/alpine/payment_reminder'
Alpine.data('paymentReminder', paymentReminder)

import invoiceDeliveryMethods from '~/alpine/invoice_delivery_methods'
Alpine.data('invoiceDeliveryMethods', invoiceDeliveryMethods)

import { referenceTransactions, billingInvoices, billingReports, billingPayments, reportsTable } from '~/alpine/billing'
Alpine.data('referenceTransactions', referenceTransactions)
Alpine.data('billingInvoices', billingInvoices)
Alpine.data('billingReports', billingReports)
Alpine.data('billingPayments', billingPayments)
Alpine.data('reportsTable', reportsTable)

import pagination from '~/alpine/pagination'
Alpine.data('pagination', pagination)

import copyToClipboard from '~/alpine/copy_to_clipboard'
Alpine.data('copyToClipboard', copyToClipboard)

import course from '~/alpine/course'
Alpine.data('course', course)

import schoolHomepage from '~/alpine/school_homepage'
Alpine.data('schoolHomepage', schoolHomepage)

import studentPurchasesContracts from '~/alpine/student_purchases_contracts'
Alpine.data('studentPurchasesContracts', studentPurchasesContracts)

import studentFeature from '~/alpine/student_feature'
Alpine.data('studentFeature', studentFeature)

import { selectize, flatpickrInput, dataTable } from '~/alpine/initializers'
Alpine.data('selectize', selectize)
Alpine.data('flatpickrInput', flatpickrInput)
Alpine.data('dataTable', dataTable)

import tableFilter from '~/alpine/table_filter'
Alpine.data('tableFilter', tableFilter)

import searchForm from '~/alpine/search_form'
Alpine.data('searchForm', searchForm)

import { courseOutline, studyModule, curriculumTrainings } from '~/alpine/course_outlines'
Alpine.data('courseOutline', courseOutline)
Alpine.data('studyModule', studyModule)
Alpine.data('curriculumTrainings', curriculumTrainings)

import { tableSort, SortHeader } from '~/alpine/table_sort'
Alpine.data('tableSort', tableSort)
Alpine.bind('SortHeader', SortHeader)

import cookieBanner from '~/alpine/cookie_banner'
Alpine.data('cookieBanner', cookieBanner)

window.Alpine = Alpine
window.Alpine.start()
